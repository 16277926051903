import { defineStore } from 'pinia';
import Api from '../types/apis/Api';
import Breadcrumb from '../types/app/Breadcrumb';
import DocPage from '../types/documentation/DocPage';
import Environment from '../types/environments/Environment';
import ApiVersion from '../types/apis/ApiVersion';

const apiStore = defineStore({
  id: 'api',
  state: () => ({
    apis: [] as Api[],
    images: [] as { apiId: number; image: string }[],
    docPageBreadcrumbs: [] as Breadcrumb[],
    docCurrentParent: {} as DocPage,
    showDeployErrorDialog: false,
    deployApiObjct: {} as { api: Api; environment: Environment; version: ApiVersion },
  }),
  getters: {
    getAPIs(): Api[] {
      return this.apis;
    },
    getDocParent(): DocPage {
      return this.docCurrentParent;
    },
    getDocPageBreadcrumbs(): Breadcrumb[] {
      return this.docPageBreadcrumbs;
    },
  },
  actions: {
    setDocPageBreadcrumbs(docPageBreadcrumbs: Breadcrumb[]) {
      this.docPageBreadcrumbs = docPageBreadcrumbs;
    },
    setDocParent(docCurrentParent: DocPage) {
      this.docCurrentParent = docCurrentParent;
    },
    getAPIByID(apiId: number): Api | undefined {
      return this.apis.find((apiInArray: Api) => Number(apiInArray.id) === Number(apiId));
    },
    getImage(apiId: number): string | undefined {
      const apiImage: { apiId: number; image: string } | undefined = this.images.find(
        (aI: { apiId: number; image: string }) => Number(aI.apiId) === Number(apiId),
      );
      if (apiImage) {
        return apiImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(apiId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { apiId: number; image: string }) => {
        if (Number(aI.apiId) === Number(apiId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ apiId, image });
      }
    },
    deleteImage(apiId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { apiId: number; image: string }) => Number(aI.apiId) === Number(apiId),
      );
      this.images.splice(indexOfObject, 1);
    },
    cleanDeployApiObjct(): void {
      this.deployApiObjct = {} as { api: Api; environment: Environment; version: ApiVersion };
    },
  },
});

export default apiStore;
