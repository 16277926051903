<template>
  <TheAppShell
    :navigation="navigation"
    :user="userName"
    :image-src="userImage"
    :show-navigation-level2-bar="showLevel2Bar"
    :allow-dark-mode="config.allowDarkMode"
    :lvl1-bar-open-state-key="localStorageKey"
    @lvl1-bar-open="lvl1BarOpenHandler"
    @logout="logout"
    @support="support"
    @settings="settings"
  >
    <template v-if="level2BarCompId" #NavigationMobileLevel2Bar>
      <ApiSidebarController
        v-if="level2BarType === Level2BarEnum.API"
        :api-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <EnvironmentSidebarController
        v-if="level2BarType === Level2BarEnum.ENVIRONMENT"
        :environment-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <ApplicationSidebarController
        v-if="level2BarType === Level2BarEnum.APPLICATION"
        :application-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <ApiProductSidebarController
        v-if="level2BarType === Level2BarEnum.API_PRODUCT"
        :api-product-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <ConfigurationSidebarController
        v-if="level2BarType === Level2BarEnum.CONFIGURATION"
        :hide-bar-by-small-resolution="false"
      />
    </template>

    <template #NavigationDesktopAboveUser>
      <div>
        <TierSidebarButton
          v-if="showTierButton()"
          :mode="TutorialMode.DESKTOP"
          :lvl1-bar-open="lvl1BarOpen"
        />
        <TierDowngradedDialog
          v-if="store.SaaSInfo.showTierDowngradeMessage && showTierButton()"
          :saas-info="store.SaaSInfo"
          @close-dialog="hideTierDowngradeMessage"
          @support="support"
        />
        <div class="pt-4">
          <ObsidianAppTutorial
            v-if="config.allowTutorial"
            :lvl1-bar-open="lvl1BarOpen"
            :mode="TutorialMode.DESKTOP"
          />
        </div>
      </div>
    </template>

    <template #NavigationMobileAboveUserWithCloseMenu>
      <div v-if="showLevel2Bar">
        <TierSidebarButton
          v-if="showTierButton()"
          :mode="TutorialMode.MOBILE_WITH_LVL2"
          :lvl1-bar-open="false"
        />
        <TierDowngradedDialog
          v-if="store.SaaSInfo.showTierDowngradeMessage && showTierButton()"
          :saas-info="store.SaaSInfo"
          @close-dialog="hideTierDowngradeMessage"
          @support="support"
        />
      </div>
      <div v-else>
        <TierSidebarButton
          v-if="showTierButton()"
          :mode="TutorialMode.MOBILE"
          :lvl1-bar-open="true"
        />

        <TierDowngradedDialog
          v-if="store.SaaSInfo.showTierDowngradeMessage && showTierButton()"
          :saas-info="store.SaaSInfo"
          @close-dialog="hideTierDowngradeMessage"
          @support="support"
        />
      </div>
    </template>

    <template #NavigationMobileAboveUser>
      <div v-if="showLevel2Bar">
        <ObsidianAppTutorial
          v-if="config.allowTutorial"
          :lvl1-bar-open="false"
          :mode="TutorialMode.MOBILE_WITH_LVL2"
        />
      </div>
      <div v-else>
        <ObsidianAppTutorial
          v-if="config.allowTutorial"
          :lvl1-bar-open="true"
          :mode="TutorialMode.MOBILE"
        />
      </div>
    </template>
  </TheAppShell>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TheAppShell } from '@apiida/vue-components';
import AuthService from '../../services/app/AuthService';
import mainStore from '../../stores/MainStore';
import UserService from '../../services/configuration/UserService';
import config from '../../config';
import ObsidianAppTutorial from './ObsidianAppTutorial.vue';
import RoleGuards from '../../routers/guards/RoleGuards';
import ApiSidebarController from '../api/ApiSidebarController.vue';
import EnvironmentSidebarController from '../environments/EnvironmentSidebarController.vue';
import ApiProductSidebarController from '../apiProduct/ApiProductSidebarController.vue';
import ConfigurationSidebarController from '../configuration/ConifgurationSidebarController.vue';
import ApplicationSidebarController from '../applications/ApplicationSidebarController.vue';
import { Level2BarEnum } from '@/types/enums/Level2BarEnum';
import TierSidebarButton from './TierSidebarButton.vue';
import TierDowngradedDialog from './TierDowngradedDialog.vue';
import ObService from '../../services/app/ObService';
import SaaSGuard from '../../routers/guards/SaaSGuard';
import { TutorialMode } from '@/types/enums/TutorialMode';
import openNewTap from '../../helper/OpenLinkHelper';
import snowf from 'snowf';
import { checkDarkMode } from '@/helper/DarkModeHelper';

// https://github.com/Fuxy526/snowf?tab=readme-ov-file#default-options
if (config.allowLetItSnow) {
  snowf.init({
    size: 1,
    amount: 500,
    swing: 1,
    color: checkDarkMode() ? '#fff' : '#033D58',
  });
}

const tmpNavigation = [
  { name: 'Home', route: 'WelcomeView', icon: 'Home' },
  { name: 'APIs', route: 'APIs', icon: 'API' },
  { name: 'API Products', route: 'ApiProducts', icon: 'Types' },
];

const billingContactNavigation = [
  { name: 'Home', route: 'WelcomeView', icon: 'Home' },
  { name: 'Configuration', route: 'ConfigurationBillingInformation', icon: 'Settings' },
];

const emits = defineEmits(['user-logged-out']);

const localStorageKey = 'AdminPortalLvl1BarState';
const isSaas = ref(false);

const store = mainStore();
const router = useRouter();
const routeRef = ref(useRoute());
const route = useRoute();

const userName = ref<string>('');
const userImage = ref<string>('');

const isGlobalAdmin = ref(false);
const isBillingContact = ref(false);

const navigation = ref<{ name: string; route: string; icon: string }[]>([]);
const showLevel2Bar = ref<boolean>(false);
const level2BarCompId = ref<number>();
const level2BarType = ref<Level2BarEnum>();
const lvl1BarOpen = ref<boolean>(true);

// region navigation and misc

async function loadNavigation() {
  navigation.value = [...tmpNavigation];
  isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
  isBillingContact.value = RoleGuards.isBillingContact();
  if (RoleGuards.isGlobalAdmin()) {
    navigation.value.push({
      name: 'Governance',
      route: 'Governance',
      icon: 'Bar graph',
    });
  }

  navigation.value.push({
    name: 'Environments',
    route: 'Environments',
    icon: 'Storage',
  });

  navigation.value.push({
    name: 'Applications',
    route: 'Applications',
    icon: 'Players',
  });

  if (RoleGuards.isAdminOrGlobalAdmin()) {
    if (isGlobalAdmin.value) {
      navigation.value.push({
        name: 'Configuration',
        route: 'ConfigurationGeneral',
        icon: 'Settings',
      });
    } else {
      navigation.value.push({
        name: 'Configuration',
        route: 'ConfigurationUsersIndex',
        icon: 'Settings',
      });
    }
    navigation.value = [...new Set(navigation.value)];
  }

  if (isBillingContact.value) {
    navigation.value = billingContactNavigation;
  }
}

async function logout() {
  const resultLogout = await AuthService.logout();
  userName.value = '';

  // When the Boomi Platform is connected, we redirect to the provider's logout page
  if (typeof resultLogout === 'string') {
    console.log('Redircet to Boomi Identity Provider (logout) ' + resultLogout);
    window.location.assign(resultLogout);
  } else {
    // If logout page not set, do not automatically login after a logout
    store.preventAutoLoginForBoomiSSO = true;
    emits('user-logged-out');
  }
}

function support() {
  openNewTap(config.supportUrl);
}

async function settings() {
  router.push({ name: 'AccountSettings' });
}

// endregion

// navigation bars

function level2BarHandler() {
  showLevel2Bar.value = false;

  if (routeRef.value.path.includes(Level2BarEnum.API)) {
    level2BarType.value = Level2BarEnum.API;
    showLevel2Bar.value = true;
    level2BarCompId.value = +routeRef.value.params.apiId;
    return;
  }

  if (routeRef.value.path.includes(Level2BarEnum.ENVIRONMENT)) {
    level2BarType.value = Level2BarEnum.ENVIRONMENT;
    showLevel2Bar.value = true;
    level2BarCompId.value = +routeRef.value.params.environmentId;
    return;
  }

  if (routeRef.value.path.includes(Level2BarEnum.APPLICATION)) {
    level2BarType.value = Level2BarEnum.APPLICATION;
    level2BarCompId.value = +routeRef.value.params.applicationId;
    showLevel2Bar.value = true;
    return;
  }

  if (routeRef.value.path.includes(Level2BarEnum.API_PRODUCT)) {
    level2BarType.value = Level2BarEnum.API_PRODUCT;
    level2BarCompId.value = +routeRef.value.params.apiProductId;
    showLevel2Bar.value = true;
    return;
  }

  if (routeRef.value.path.includes(Level2BarEnum.CONFIGURATION)) {
    level2BarType.value = Level2BarEnum.CONFIGURATION;
    level2BarCompId.value = -1;
    showLevel2Bar.value = true;
  }
}

function lvl1BarOpenHandler(flag: boolean) {
  lvl1BarOpen.value = flag;
}

// endregion

onMounted(async () => {
  loadNavigation();
  ObService.loadInitialGeneralInformation();
  userName.value = `${store.getLoggedInUser.firstName} ${store.getLoggedInUser.lastName}`;
  UserService.getCurrentImage(store.getLoggedInUser.id, false, (base64Image: string) => {
    userImage.value = base64Image;
  });
  isSaas.value = await SaaSGuard.isSaas();
});

watch(
  () => route.name,
  () => {
    level2BarHandler();
  },
);

// region Tier

function hideTierDowngradeMessage() {
  store.SaaSInfo.showTierDowngradeMessage = false;
  ObService.hideTierDowngradeMessage();
}

function showTierButton(): boolean {
  if (!config.enableTierAndBilling) return false;

  if (isSaas.value === false) {
    return false;
  }

  if (isGlobalAdmin.value && isSaas.value) {
    return true;
  }

  return !!(isBillingContact.value && isSaas.value);
}

// endregion
</script>
