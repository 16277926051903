<template>
  <nav
    :data-test-id="'navigationDesktop_navigation'"
    aria-label="Sidebar"
    class="flex flex-col"
    :class="[lvl1BarOpen ? 'items-left' : 'items-center']"
  >
    <router-link
      :data-test-id="`${mode}_tierSidebarButton`"
      :to="{ name: 'TierView' }"
      class="menu-link flex items-center p-2 rounded-lg text-gray-200 hover:bg-primary hover:text-secondary"
      :title="getTierName"
    >
      <BaseIcon
        name="Rocket"
        label="Open Tier Management"
        icon-class="text-2xl"
        aria-hidden="true"
      />
      <span v-if="lvl1BarOpen" class="pl-2">{{ getTierName }}</span>
    </router-link>
  </nav>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { BaseIcon } from '@apiida/vue-components';
import { TutorialMode } from '@/types/enums/TutorialMode';
import mainStore from '../../stores/MainStore';

defineProps({
  lvl1BarOpen: {
    type: Boolean,
    required: true,
  },
  mode: {
    type: String as PropType<TutorialMode>,
    required: true,
  },
});

const store = mainStore();

const getTierName = computed(() => {
  if (store.SaaSInfo.tierInfo && store.SaaSInfo.tierInfo.tier) {
    return (
      store.SaaSInfo.tierInfo.tier.charAt(0).toUpperCase() +
      store.SaaSInfo.tierInfo.tier.slice(1).toLowerCase()
    );
  }
  return 'Loading...';
});
</script>
